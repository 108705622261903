import api from './api'



class  FrontendService {

    async getblogdata(search, categoryid,userId, companyid, pagesize,pageNo) {
        let LanguageId = '364697eb-c5d6-44fa-8c08-ece45aed1844'
        return await api.get(`Blog/GetBlogLists/${search}/${categoryid}/${userId}/${companyid}/${pagesize}/${LanguageId}/${pageNo}`)
    }
    async getblogcategorylist(userid) {
        let LanguageId = '364697eb-c5d6-44fa-8c08-ece45aed1844'
        return await api.get(`Blog/blogcategorylist/${userid}/${LanguageId}`)
    }
    async getblogdetailbyid(id,companyId,userId) {
        return api.get(`Blog/GetBlogbyid/${id}/${companyId}/${userId}`)
    }
    async GetPackageList(searchModel) {
        return await api.post(`GetPackageList`, searchModel);
    }
    async GetCountryList(masterNames, masterKeyText, userId) {
        return await api.get(`GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);
    }
    async GetStateList() {
        return await api.get('GetStateList');
    }
    async GetPackageDetailsById(subscriptionId) {
        return await api.get(`GetPackageDetialsByID/${subscriptionId}`);
    }
    async AddEditCompany(companyData) {
        return await api.post(`ManageCompany/AddEditCompany`, companyData);
    }
    async savecontactus(contactusform) {
        return api.post(`ContactUs`, contactusform)
    }
    async GetDynamicPageContent(pageURL, companyId = null) {
        companyId = (companyId == null) ? '00000000-0000-0000-0000-000000000000' : companyId;
        return await api.get(`DynamicPages/GetDynamicPageContent/${pageURL}/${companyId}`);
    }
    async AddSubscriber(manageSubscribersModel) {
        return await api.post(`ManageSubscribers/AddEditSubscribers`, manageSubscribersModel);
    }
    async UnSubscribed(Email, companyId = null) {
        companyId = (companyId == null) ? '00000000-0000-0000-0000-000000000000' : companyId;
        return await api.get(`ManageSubscribers/UnsubscribeNewletter/${Email}/${companyId}`)
    }
    async getTestimonailList() {
        let companyId = '00000000-0000-0000-0000-000000000000';
        let userId = '00000000-0000-0000-0000-000000000000';
        let langaugeId ='364697eb-c5d6-44fa-8c08-ece45aed1844'
        return await api.get(`ManageTestimonial/getTestimonailList/${companyId}/${userId}/${langaugeId}`);
    }
    async getnewslist(id, companyid, pagesize) {
        var langaugeId = null;
        if (companyid == null || companyid=='')
            companyid = 'null'
        if (id == null || id)
            id = 'null'
        return api.get(`ContactUs/getnewslist/${id}/${companyid}/${pagesize}/${langaugeId}`)
    }
    async getnewsdetailbyid(id) {
        debugger
        return api.get(`ContactUs/getnewsbyid/${id}`)
    }
    async GetSocialLinkList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId, status) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") {
            searchFields = "00Test";

            return await api.get(`SocialMediaLink/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}/${status}`);
        }
    }
}

export default new FrontendService();