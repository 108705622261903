<template>

    <!--====== Header Part Start ======-->
    <header class="main-header header-three">
        <!--Header-Upper-->
        <div class="header-upper">
            <div class="container clearfix">
                <div class="header-inner py-20">
                    <div class="logo-outer">
                        <div class="logo">
                            <a href="/" class="navbar-brand">
                                <img src="../../assets/images/logo.png" alt="logo">
                            </a>
                        </div>
                    </div>

                    <div class="nav-outer d-flex align-items-center clearfix mx-lg-auto">
                        <!-- Main Menu -->
                        <nav class="main-menu navbar-expand-lg">
                            <div class="navbar-header">
                                <div class="logo-mobile"><a href="/"><img src="assets/images/logos/logo.png" alt="Logo"></a></div>
                                <!-- Toggle Button -->
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="main-menu">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                            </div>

                            <div class="navbar-collapse collapse clearfix" id="main-menu">
                                <ul class="navigation clearfix">
                                    <li>
                                        <router-link href="javascript:;" class="nav-link" :to="{ name: 'Features'}">Features</router-link>
                                    </li>
                                    <li>
                                        <router-link class="nav-link" href="javascript:;" :to="{ name: 'pricing'}">Pricing</router-link>
                                    </li>
                                    <li>
                                        <router-link class="nav-link" href="javascript:;" :to="{ name: 'Resources'}">Resources</router-link>
                                    </li>
                                    <li>
                                        <router-link class="nav-link" href="javascript:;" to="/Aboutus">Why Kotina</router-link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="menu-right d-none d-lg-flex align-items-center">
                        <router-link href="javascript:;" class="theme-btn style-two" to="/requestdemo">
                            Get Started <i class="fas fa-arrow-right"></i>
                        </router-link>
                    </div>

                </div>
            </div>
        </div>
        <!--End Header Upper-->

    </header>
    <!--====== Header Part End ======-->
    <!-- :class="(isscrolled)?'nav-bg fixed-top navigation':'fixed-top navigation'" -->
</template>
<script>
    export default {
        name: "HeaderView",
        data() {
            return {
                scrollPosition: null,
                isscrolled:false
            }
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        //created() {
        //    alert(1);
        //    if ($('.feedback-three-wrap').length) {

        //        $('.feedback-three-wrap').slick({
        //            dots: true,
        //            infinite: true,
        //            autoplay: true,
        //            autoplaySpeed: 5000,
        //            arrows: false,
        //            appendDots: $('.slider-dots-area'),
        //            centerMode: false,
        //            speed: 1000,
        //            slidesToShow: 2,
        //            slidesToScroll: 1,
        //            responsive: [
        //                {
        //                    breakpoint: 767,
        //                    settings: {
        //                        slidesToShow: 1,
        //                    }
        //                }
        //            ]
        //        });
        //    }
        //},
        methods: {
            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }
               
            }
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        }
    }
</script>