<template>
    <loading v-model:active="isLoading">
        <!--<div class="loader-wrapper">
        <div class="row loader-img">
        <div class="col-12 status_bar">
        <img src="../assets/images/logo/Suploader.gif" class="loader_img" alt="">
        <span class="loader">Load&nbsp;ng</span>
        </div>
        </div>
        </div>-->
        <div class="preloader"></div>
    </loading>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    export default {
        props: {
            isLoading: Boolean,
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
            }
        },
        created() {
            /*this.vmodeldata = this.editordata*/
        }
    }
</script>
<style>
</style>