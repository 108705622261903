import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    //{
    //    path: '/',
    //    name: 'home',
    //    component: HomeView
    //},
    {
        path: '/contactus',
        name: 'ContactUs',
        component: () => import('@/views/ContactUs.vue')
    },
    {
        path: '/Package',
        name: 'Package',
        component: () => import('@/views/PackageView.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/views/Blog/Blog.vue')
    },
    {
        path: '/blogdetail',
        name: 'Blog Detail',
        component: () => import('@/views/Blog/blogdetail.vue')
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('@/views/Pricing/pacakgelist.vue')
    },
    {
        path: '/checkout/:subscriptionId',
        name: 'checkout',
        component: () => import('@/views/Pricing/checkoutPackage.vue')
    },
    {
        path: '/thankyou',
        name: 'thankyou',
        component: () => import('@/views/Thankyou.vue')
    },
    {
        path: '/Aboutus',
        name: 'Aboutus',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Howitworks',
        name: 'Howitworks',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/PrivacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/PressRelease',
        name: 'PressRelease',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/contactus',
        name: 'ContactUs',
        component: () => import('@/views/ContactUs.vue')
    },
    {
        path: '/requestdemo',
        name: 'requestdemo',
        component: () => import('@/views/RequestDemo.vue')
    },
    {
        path: '/testimonailList',
        name: 'testimonailList',
        component: () => import('@/views/Testimonial/testimonialList.vue'),
    },
    {
        path: '/Features',
        name: 'Features',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Resources',
        name: 'Resources',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Careers',
        name: 'Careers',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Accounting',
        name: 'Accounting',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Communications',
        name: 'Communications',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Marketing',
        name: 'Marketing',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/Leasing&Applications',
        name: 'Leasing&Applications',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/TenantPortal',
        name: 'TenantPortal',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/pressnews',
        name: 'Press News',
        component: () => import('@/views/News/news.vue')
    },
    {
        path: '/pressnewsdetail/:id',
        name: 'Press Detail',
        component: () => import('@/views/News/newsdetail.vue')
    },
    {
        path: '/TermsandConditions',
        name: 'Terms and Conditions',
        component: () => import('@/views/dynamicPages/dynamicPages.vue')
    },
    {
        path: '/unsubscribenewsletter/:email',
        name: 'Unsubscribe Newsletter',
        component: () => import('@/views/shared/UnsubscribeNewsletter/unsubscribenewsletter.vue')
    },
    {
        path: '/ErrorPage',
        name: 'Page Not Found',
        component: () => import('@/views/shared/errorPage.vue')
    }

    //{
    //    path: '/thankyoucu',
    //    name: 'thankyoucu',
    //    component: () => import('@/views/thankyoucontactus.vue')
    //},
    //{
    //    path: '/thankyoureq',
    //    name: 'thankyoureq',
    //    component: () => import('@/views/thankyourequestdemo.vue')
    //},
    //{
    //    path: '/thankyoucomp',
    //    name: 'thankyoucomp',
    //    component: () => import('@/views/thankyoucompany.vue')
    //}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.length == 0) {
        next('/ErrorPage')
    }
    else {
        next();
    }
    document.title = `Kotina/` + `${to.name}`;
    window.scrollTo(0, 0);

})


export default router
