import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap"
//import "primevue/resources/themes/vela-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons 

import 'vue-loading-overlay/dist/css/index.css';

import ToastService from 'primevue/toastservice';
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import Button from "primevue/button";
import HelperFunction from '@/Helpers/Helpers.js';
import { LoadingPlugin } from 'vue-loading-overlay';
import * as ConfirmDialog from "vuejs-confirm-dialog";
import "@/assets/css/fontawesome.5.9.0.min.css"
import "@/assets/css/flaticon.css"
import "@/assets/css/bootstrap.4.5.3.min.css"
import "@/assets/css/magnific-popup.css"
import "@/assets/css/slick.css"
import "@/assets/css/animate.min.css"
import "@/assets/css/nice-select.css"
import "@/assets/css/spacing.min.css"
import "@/assets/css/menu.css"
import "@/assets/css/style.css"
import "@/assets/css/responsive.css"

import jquery3 from "./assets/js/jquery-3.6.0.min.js";
import bootstrap4 from "./assets/js/bootstrap.4.5.3.min.js"
import appear from "./assets/js/appear.js"
import wow from "./assets/js/wow.min.js"
import isotope from "./assets/js/isotope.pkgd.min.js"
import circle from "./assets/js/circle-progress.min.js"
//import imagesloaded from "./assets/js/imagesloaded.pkgd.min.js"
//import niceselect from "./assets/js/jquery.nice-select.min.js"
//import magnific from "./assets/js/jquery.magnific-popup.min.js"
import slick from "./assets/js/slick.min.js"
import script from "./assets/js/script.js"




import Loading from '@/Layout/Loader.vue';
const app = createApp(App).use(router)

window.$ = window.jQuery = require('jquery');

app.use(PrimeVue);

    app.mixin("", jquery3);
    app.mixin("", bootstrap4);
    app.mixin("", appear);
    app.mixin("", wow);
    app.mixin("", isotope);
    app.mixin("", circle);
    //app.mixin("", imagesloaded);
    //app.mixin("", niceselect);
    //app.mixin("", magnific);
    app.mixin("", slick);
    app.mixin("", script);
app.use(LoadingPlugin);
app.use(ToastService);
app.use(ConfirmDialog)
app.component("Toast", Toast);
app.component("Button", Button);
app.component('Loading', Loading);

app.mixin({
    methods: {
        success: function (msg) {
            this.$toast.add({ severity: 'success', summary: "Success Message", detail: msg, life: 3000 });
        },
        error: function (msg) {
            this.$toast.add({
                severity: 'error', summary: "Error Message", detail: msg, life: 3000
            });
        },
        warn: function (msg) {
            this.$toast.add({
                severity: 'warn', summary: "Warning Message", detail: msg, life: 3000
            });
        }
    },
})

app.config.globalProperties.$filters = HelperFunction;

app.use(function (req, res, next) {
    const isProduction = process.env.VUE_APP_PRODUCTION;
    if (isProduction.toLocaleLowerCase() == "true") {
        if (location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }
});
app.mount('#app');
