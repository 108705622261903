<template>
    <Header />
    <div class="page-body-wrapper">
        <div class="page-body">
            <router-view />
        </div>
        <!-- END PAGE CONTENT -->
    </div>
    <Footer />
    <!-- Scroll Top Button -->
    <button class="scroll-top scroll-to-target" data-target="html"><span class="fa fa-angle-up"></span></button>
</template>
<script>
    
    import Header from '@/Layout/header/Header.vue';
    import Footer from '@/Layout/footer/Footer.vue';
    export default {
        name:"layoutName",
        components: {
            Header,
            Footer
            }
        }
</script>
<style scoped>

</style>