<template>
    <section class="page-body">
        <layout />
        <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
    </section>
    <DialogsWrapper />
</template>

<script>
      import layout from '@/Layout/layout.vue';
     export default {
        components: {
            layout
        },
         created()
         {
             localStorage.setItem("SupuserId","00000000-0000-0000-0000-000000000000");
             localStorage.setItem("SupcompanyId","00000000-0000-0000-0000-000000000000");
         }

    }    
</script>

